import {
  createStore
} from "vuex";
const store = createStore({
  state() {
    return {
      user: {},
      id: "",
      uuid: "",
      vehicleTab: "",
      orderTab: "",
      orderStatus: "",
      title: "",
      isHideMenu: false,
      parentId: "",
      subCategoryId: "",
      categoryAddType: "",
      subSubCategorId: "",
    };
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setId(state, payload) {
      state.id = payload;
    },
    setIsHideMenu(state, payload) {
      state.isHideMenu = payload;
    },
    setUuid(state, payload) {
      state.uuid = payload;
    },
    setUserId(state, payload) {
      state.userId = payload;
    },
    setUserDetailsTab(state, payload) {
      state.userDetailsTap = payload;
    },

  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getTitle(state) {
      return state.title;
    },
    getId(state) {
      return state.id;
    },
    getIsHideMenu(state) {
      return state.isHideMenu;
    },
    getUuid(state) {
      return state.uuid;
    },
    getUserId(state) {
      return state.userId;
    },

    getUserDetailsTab(state) {
      return state.userDetailsTap;
    },
  },
  actions: {},
});

export default store;