import moment from "moment";

class HelperService {
  constructor() {
    this.accessLevel = new Map();
  }

  sorting(sortBy, id) {
    var orderBy = "ASC";
    var className = "sorting_asc";
    var temp = document.getElementById(id);
    if (temp) {
      if (temp.className.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      if (className == "sorting_asc") {
        temp.classList.add("sorting_asc");
        temp.classList.remove("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        temp.classList.add("sorting_desc");
        temp.classList.remove("sorting_asc");
        orderBy = "DESC";
      }
    }
    var obj = {
      order_by: orderBy,
      sort_by: sortBy,
    };
    return obj;
  }

  getFormatedDate(d) {
    if (!d || d == null) {
      return "";
    }
    return moment(d).format("DD MMM, YYYY");
  }

  getFormatedDateAndTime(dt) {
    if (!dt || dt == null) {
      return "";
    }
    return moment.utc(dt).local().format("DD MMM YYYY, hh:mm A");
  }

  getFormatedDateAndTimeCalender(dt) {
    if (!dt || dt == null) {
      return "";
    }
    return moment.utc(dt).local().format("DD,MMM");
  }

  getTimeFromDate(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }
  
  getFormatedTime(dt) {
    if (!dt || dt == null) {
      return "";
    }
    return moment.utc(dt).local().format("hh:mm A");
  }

  getTime(dt) {
    if (!dt || dt == null) {
      return "";
    }
    return moment(dt, 'HH:mm:ss') ;
  }


  sendFormatedDate(d, type) {
    if (type == "date") {
      return moment(d).format("YYYY-MM-DD");
    } else if (type == "month_year") {
      return moment(d).format("YYYY-MM");
    } else if (type == "year") {
      return moment(d).format("YYYY");
    } else {
      return moment(d).format("YYYY-MM-DD");
    }
  }

  allowOnlyNumericValue(e) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }
  }

  getTitleCase(value) {
    if (!value) {
      return "";
    } else {
      var str = "";
      var arr = value.split("_");
      for (var i in arr) {
        if (i == 0) {
          str =
            arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
        } else {
          str +=
            " " +
            arr[i].charAt(0).toUpperCase() +
            arr[i].substring(1).toLowerCase();
        }
      }
      return str;
    }
  }

  setAccessLevel(accessLevel) {
    this.accessLevel = accessLevel;
  }

  getAccessLevel(moduleName) {
    console.log(moduleName);
    console.log(this.accessLevel);
    if (this.accessLevel.length > 0) {
      for (var i = 0; i < this.accessLevel.length; i++) {
        if (moduleName == this.accessLevel[i].name) {
          return this.accessLevel[i].access;
        }
      }
    }
  }

  allowDecimalValue(e) {
    var charCode = e.which ? e.which : e.keyCode;
    var number = e.target.value.split(".");
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      e.preventDefault();
    }
    //get the carat position
    var caratPos = this.getSelectionStart(e.target);
    var dotPos = e.target.value.indexOf(".");
    if (caratPos > dotPos && dotPos > -1 && number[1].length > 1) {
      e.preventDefault();
    }
  }

  getSelectionStart(o) {
    if (o.createTextRange) {
      var r = document.selection.createRange().duplicate();
      r.moveEnd("character", o.value.length);
      if (r.text == "") return o.value.length;
      return o.value.lastIndexOf(r.text);
    } else return o.selectionStart;
  }

  hasAccessLevel() {
    return this.accessLevel.length > 0;
  }
}

export default new HelperService({});
